  .animateline span {
    animation: animate 6s linear infinite;
  }
    
  @keyframes animate {
    0% {
      left: 0;
      transform: translate(-100%);
    }
    100% {
      left: 100%;
      transform: translate(0);
    }
  }

  .animatelineright span {
    animation: animateright 6s linear infinite;
  }
  .animatelineheight span {
    animation: animateheight 6s linear infinite;
  }
  .animatelineheightreverce span {
    animation: animateheightreverce 6s linear infinite;
  }

  @keyframes animateheight {
    0% {
      top: 0%;
      transform: translateY(100%);
    }
    100% {
      bottom: 100%;
      transform: translateY(0%);
    }
    100% {
      top: 0%;
      transform: translateY(-100%);
    }
  }

  @keyframes animateright {
    0% {
      right: 0;
      transform: translate(100%);
    }
    100% {
      right: 100%;
      transform: translate(0%);
    }
  }